import React from 'react';

import HelpLayout from '../../../components/HelpLayout';
import HelpArticle from '../../../components/HelpArticle';
import SEO from '../../../components/SEO';

const HowToAddVideoInHtmlPage = ({ location }) => {
  return (
    <HelpLayout location={location}>
      <SEO
        title="How to add a video in HTML"
        description="Learn how to add a video to your HTML page."
      />
      <HelpArticle title="How to add a video in HTML">
        <HelpArticle.StepContent>
          <p>
            In your video library click on the video you want to embed. The
            video detail page will show.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            Click on <strong>Actions</strong> and choose{' '}
            <strong>Get page embed code</strong>. Pop-up window will appear.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            Now you can pick either <strong>Responsive</strong> or{' '}
            <strong>Fixed</strong> option. The recommended default is{' '}
            <strong>Responsive</strong>, which means the video will adjust its
            size according to the container you insert it into. In case you need
            a specific size of the video, you can choose the{' '}
            <strong>Fixed</strong> option and set the desired width and height
            of the video.
            <br />
            Click on the <strong>Copy</strong> button. Now you have the embed
            code in your clipboard.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            Go to your HTML page editor and paste the embed code where the video
            should be placed.
          </p>
        </HelpArticle.StepContent>
      </HelpArticle>
    </HelpLayout>
  );
};

export default HowToAddVideoInHtmlPage;
